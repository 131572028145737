import './assets/main.css'
import 'vue3-toastify/dist/index.css';

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { session } from "@/data/session"
import { resourcesPlugin, frappeRequest, setConfig } from 'frappe-ui'
import { OhVueIcon, addIcons } from "oh-vue-icons";
import { FaRunning, FcCalendar, MdAddlocationalt, FcCheckmark } from "oh-vue-icons/icons";

addIcons(FaRunning, FcCalendar, MdAddlocationalt, FcCheckmark);

const app = createApp(App)
app.component("v-icon", OhVueIcon);

app.use(router)
setConfig('resourceFetcher', frappeRequest)
app.use(resourcesPlugin)
app.provide("$session", session)

app.mount('#app')
