import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory('/events'),
  routes: [
    {
      path: '/',
      name: 'home',
      component: ()=> import('../views/HomeView.vue'),
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/AboutView.vue'),
    },
    {
      path: '/marathon/:marathon_id',
      name: 'marathon',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/MarathonView.vue'),
      props: true
    },
    {
      path: '/register-form/:event_id',
      name: 'EventRegisterForm',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/EventRegisterForm.vue'),
      props: true
    },
    {
      path: '/payment/success/:registration_id',
      name: 'EventPaymentSuccess',
      component: () => import('../views/EventPaymentSuccess.vue'),
      props: true
    },
    {
      path: '/payment/failed/:registration_id',
      name: 'EventPaymentFailed',
      component: () => import('../views/EventPaymentFailed.vue'),
      props: true
    }
  ],
})

export default router
