import { computed, reactive } from "vue"
import { createResource, call } from "frappe-ui"
import { userResource } from "./user"
import router from "@/router"

export function sessionUser() {
    let cookies = new URLSearchParams(document.cookie.split("; ").join("&"))
    let _sessionUser = cookies.get("user_id")
    if (_sessionUser === "Guest") {
        _sessionUser = null
    }
    return _sessionUser
}

export const session = reactive({
    login: async (email, password) => {
        const response = await call("login", { usr: email, pwd: password })
        if (response.message === "Logged In") {
            userResource.reload()
            session.user = sessionUser()
            router.replace(response.default_route || "/")
        }
        return response
    },
    logout: createResource({
        url: "logout",
        onSuccess() {
            userResource.reset()
            session.user = sessionUser()
            router.replace({ name: "Login" })
            window.location.reload()
        },
    }),
    user: sessionUser(),
    isLoggedIn: computed(() => !!session.user),
})
